const configs = {
  appId: "wildtv",
  appName: "WildTV",
  posthog: "phc_TxKxa7qVvLMhLcFnXrAOocWYbk39WcMk6YWNxjnL0ay",
  modules: {
    home: true,
    live: false,
    search: false,
    series: false,
    movies: false,
    watchlist: true,
    recording: false,
    favorite: true,
    seasons: true,
    branding: true,
  },
  search: {
    includeEpisodes: true,
  },
  playback: {
    subtitleLanguages: false,
    audioLanguages: false,
  },
  banner: {
    useCoverUrl: false,
    hasStaticBanner: false,
  },
  settings: {
    terms: "https://www.wildtvplus.ca/en/legal/terms-of-service",
    email: "wildtvplus@wildtv.ca",
  },
  iap: {
    androidtv: {
      prices: {
        // monthly: "com.maz.combo.3602.sub",
        // yearly: "com.maz.combo.3602.sub2",
        monthly: {
          id: "com.maz.combo.3602.sub",
          price: "5.99CAD",
        },
        yearly: {
          id: "com.maz.combo.3602.sub2",
          price: "49.99CAD",
        },
      },
      promoPrices: {
        monthly: {
          id: "com.maz.combo.3602.sub4",
          price: "4.99CAD",
        },
        yearly: {
          id: "com.maz.combo.3602.sub52",
          price: "39.99CAD",
        },
      },
    },
    firetv: {
      prices: {
        monthly: {
          id: "com.maz.combo.3602.sub4",
          price: "5.99CAD",
        },
        yearly: {
          id: "com.maz.combo.3602.sub52",
          price: "49.99CAD",
        },
      },
      promoPrices: {
        monthly: {
          id: "com.maz.combo.3602.sub4",
          price: "4.99CAD",
        },
        yearly: {
          id: "com.maz.combo.3602.sub52",
          price: "39.99CAD",
        },
      },
    },
  },
};

export default configs;
